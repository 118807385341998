export const exportExcelColumn = [
  {
    label: "Numbering",
    field: "numbering"
  },
  {
    label: "Title",
    field: "title"
  },
  {
    label: "Station",
    field: "station"
  },
  {
    label: "Scope",
    field: "scope"
  },
  {
    label: "Type",
    field: "type"
  },
  {
    label: "Delivery Date",
    field: "date_receive"
  },
  {
    label: "Last Status",
    field: "last_status"
  },
  {
    label: "Revision Version",
    field: "revision_version"
  },
  {
    label: "Version",
    field: "version"
  },
  {
    label: "Receiver",
    field: "receiver"
  },
  {
    label: "Distribution Date",
    field: "date_dist_logistic"
  }
];
