<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Code - Nickname</label>

            <b-form-select
              v-model="filter.code"
              :options="formattedProjectOptions"
              size="lg"
              @change="setNickname($event)"
            ></b-form-select>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              v-model="filter.nickname"
              disabled
              size="lg"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <div class="d-flex">
            <button
              class="btn btn-primary btn-md mr-2"
              @click="getReports(false)"
            >
              <i class="flaticon2-search-1"></i> Show
            </button>
            <button
              class="btn btn-warning btn-md mr-2 ml-2"
              @click="getReports(true)"
            >
              <i class="flaticon2-search-1"></i> Show Latest
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row mb-5">
          <div class="col-md-4 py-2 col-sm-12">
            <vue-excel-xlsx
              :data="asbuiltData"
              :columns="columns"
              :file-name="
                `${filter.code} - ${filter.nickname} - Asbuilt Drawing`
              "
              :sheetname="'sheet1'"
            >
              <button
                class="btn btn-success btn-md mr-2"
                :disabled="asbuiltData.length === 0"
              >
                <i class="fa fa-file-excel"></i>
                Excel
              </button>
            </vue-excel-xlsx>

            <button
              class="btn btn-danger btn-md mr-2"
              @click="onExportPDF"
              :disabled="asbuiltData.length === 0"
            >
              <i class="fa fa-file-pdf"></i>
              PDF
            </button>
          </div>

          <div class="col-md-4 offset-md-4 py-2 col-sm-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-search-1"></i>
                </span>
              </div>

              <input type="text" class="form-control" placeholder="Search" />
            </div>
          </div>
        </div>

        <complete-table
          :loading="loading"
          :data="asBuiltDrawings"
          ref="contentPDF"
          id="contentPDF"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">No</span>
              </th>
              <th style="min-width: 150px">Numbering</th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 150px">Station</th>
              <th style="min-width: 150px">Scope</th>
              <th style="min-width: 150px">Type</th>
              <th style="min-width: 150px">Date</th>
              <th style="min-width: 150px">Status</th>
              <th style="min-width: 150px">Revision</th>
              <th style="min-width: 150px">Version</th>
              <th style="min-width: 150px">Receiver</th>
              <th style="min-width: 150px">Distribution Date</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i, number }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ number }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.station ? item.station.name : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.scope ? item.scope.name : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type ? item.type.name : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item.date_receive).format("DD-MM-YYYY") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.last_status ? item.last_status.code : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.revision_version }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.receiver }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item.date_dist_logistic).format("DD-MM-YYYY") }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { GET_SCOPES } from "../../../core/services/store/scope.module";
import { showToast } from "../../../core/helper/toast.helper";
import { GET_REPORT_AS_BUILT_DRAWING } from "../../../core/services/store/report-asbuilt-drawing.module";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { exportExcelColumn } from "@/view/pages/reporting/monitoringAsbuiltDrawing";
import moment from "moment";
import { logos } from "@/assets/js/components/logos64bit.js";

export default {
  components: { CompleteTable },
  data() {
    return {
      exportExcelColumn: exportExcelColumn,
      filter: {
        code: null,
        nickname: null,
        scope: null,
        revision_version: null,
        contract_no: null,
        jobdesk: [
          "interface",
          "design",
          "planner",
          "drafter",
          "koor",
          "enginer",
          "manager",
          "admin",
        ],
      },
      asbuiltData: [],
      columns: [
        {
          label: "No",
          field: "no",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Station",
          field: "stasiun",
        },
        {
          label: "Scope",
          field: "scope",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Revision",
          field: "revision",
        },
        {
          label: "Versi",
          field: "versi",
        },
        {
          label: "Receiver",
          field: "receiver",
        },
        {
          label: "Distribution Date",
          field: "transmittal_date",
        },
      ],
      data: [
        {
          id: 123,
          site_document_type: {
            code: "asd",
            name: "asbuilt drawing",
            category: {
              code: "asd",
              name: "category name",
            },
          },
          project: {
            code: "LSR123123",
            name: "project name",
            nickname: "makpar 2",
          },
          complete: 0,
          storage: "/path/to/file.txt",
        },
        {
          id: 123,
          site_document_type: {
            code: "asd",
            name: "asbuilt drawing",
            category: {
              code: "asd",
              name: "category name",
            },
          },
          project: {
            code: "LSR123123",
            name: "project name",
            nickname: "makpar 2",
          },
          complete: 1,
          storage: "/path/to/file.txt",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      scopes: (state) => state.scope.scopes?.data,
      asBuiltDrawings: (state) =>
        state.reportAsBuiltDrawing.asBuiltDrawings?.data,
      loading: (state) => state.reportAsBuiltDrawing.loading,
    }),

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.code + " - " + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    currentAsbuiltData() {
      return this.asBuiltDrawings;
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },

    excelData() {
      return this.asBuiltDrawings?.map((item) => {
        return {
          numbering: item?.numbering,
          title: item?.title,
          station: item?.station?.code,
          scope: item?.scope?.code,
          type: item?.type?.code,
          date_receive: item?.date_receive,
          last_status: item?.last_status?.code,
          revision_version: item?.revision_version,
          version: item?.version,
          receiver: item?.receiver,
          date_dist_logistic: item?.date_dist_logistic,
        };
      });
    },
  },
  methods: {
    mappingDataasbuilt(listAsbuilt) {
      let no = 0;
      this.asbuiltData = [];
      listAsbuilt.map((bom) =>
        this.asbuiltData.push({
          no: (no = no + 1).toString(),
          numbering: bom.numbering,
          title: bom.title,
          stasiun: bom.station === null ? " " : bom.station?.name,
          scope: bom.scope === null ? " " : bom.scope?.name,
          type: bom.type === null ? " " : bom.type?.name,
          date: " ",
          status: bom.last_status === null ? " " : bom.last_status.name,
          revision:
            bom.revision_version === null
              ? " "
              : bom.revision_version.toString(),
          versi: bom.version === null ? " " : bom.version.toString(),
          receiver: bom.receiver,
          transmittal_date: moment(bom.transmittal_date).format("DD-MM-YYYY"),
        })
      );
    },

    onExportPDF() {
      var doc = new jsPDF("landscape");

      doc.setFontSize(11);

      var head = [
        [
          "No",
          "Numbering",
          "Title",
          "Station",
          "Scope",
          "Type",
          "Date",
          "Status",
          "Revision",
          "Versi",
          "Receiver",
          "Distribution Date",
        ],
      ];

      var body = [];

      this.asbuiltData.map((c) => {
        const propertyValues = Object.values(c);
        body.push(propertyValues);
      });

      const logo = logos;

      const today = new Date();

      const totalPagesExp = "{total_pages_count_string}";

      var projectCode = this.filter.code !== null ? this.filter.code : "-";

      var nickname = this.filter.nickname !== null ? this.filter.nickname : "-";
      var contract_no =
        this.filter.contract_no !== null ? this.filter.contract_no : "-";

      doc.autoTable({
        headStyles: {
          halign: "center",
          valign: "middle",
        },
        head: head,
        body: body,
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
          doc.text(
            "Reporting Monitoring Asbuilt Drawing",
            data.settings.margin.left + 50,
            25
          );
          doc.setFontSize(8);

          doc.text(
            `Day/Date : ${moment(today).format("dddd")}/ ${moment(today).format(
              "ll"
            )}`,
            data.settings.margin.left + 200,
            15
          );
          doc.text(
            `Time :  ${moment(today).format("LTS")}`,
            data.settings.margin.left + 200,
            20
          );
          doc.text(
            `Contract No: ${contract_no}`,
            data.settings.margin.left + 200,
            25
          );

          doc.text(
            `Project Code: ${projectCode}`,
            data.settings.margin.left + 200,
            30
          );

          doc.text(
            `Project Name: ${nickname}`,
            data.settings.margin.left + 200,
            35
          );

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
          doc.text(str, 260, pageHeight - 10);
          doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 45 },
      });

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      doc.save(
        `${this.filter.code} - ${this.filter.nickname} - Asbuilt Drawing` +
          ".pdf"
      );
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filter.jobdesk,
      });
    },

    getScopes() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPES, {});
      }
    },

    getReports(latest) {
      if (this.filter.code) {
        this.$store
          .dispatch(GET_REPORT_AS_BUILT_DRAWING, {
            params: {
              ...this.filter,
              latest,
            },
            code: this.filter?.code,
          })
          .then(() => this.mappingDataasbuilt(this.currentAsbuiltData));
      } else {
        showToast("Failed", "Please Choose Project First", "danger");
      }
    },

    setNickname(value) {
      const project = this.projects?.find((project) => project?.code === value);

      this.filter.code = value;
      this.filter.nickname = project?.nickname;
      this.filter.contract_no = project?.contract_no;

      this.filter.revision_version = project?.dmp_rev;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Report Monitoring Asbuilt Drawing" },
    ]);

    this.getProjects();

    this.getScopes();
  },
};
</script>
